import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1250.000000) scale(0.100000,-0.100000)">
          <path d="M2905 3343 c-35 -9 -68 -37 -81 -70 -10 -23 -14 -83 -14 -213 0 -276
6 -283 231 -288 176 -4 218 5 256 55 l28 36 0 197 0 197 -28 36 c-17 23 -41
40 -65 47 -37 10 -289 12 -327 3z m256 -113 c18 -10 19 -23 19 -170 0 -187 4
-180 -115 -180 -119 0 -115 -7 -115 180 0 141 2 160 18 169 22 14 168 14 193
1z"/>
<path d="M8793 3339 c-48 -14 -80 -59 -88 -123 -4 -31 -6 -122 -4 -204 4 -160
11 -183 66 -219 22 -15 55 -19 184 -21 144 -3 160 -2 198 18 64 32 71 57 71
270 0 211 -7 238 -69 269 -35 19 -57 21 -183 20 -79 0 -157 -5 -175 -10z m261
-109 l26 -10 0 -160 0 -160 -26 -10 c-39 -15 -167 -12 -190 4 -17 13 -19 27
-19 166 0 139 2 153 19 166 23 16 151 19 190 4z"/>
<path d="M2000 3320 c0 -12 11 -60 24 -108 14 -48 43 -157 66 -242 22 -85 43
-163 46 -172 4 -15 17 -18 79 -18 l74 0 15 53 c8 28 29 105 47 170 17 65 35
115 39 110 4 -4 27 -81 51 -170 l44 -163 75 0 75 0 69 258 c38 141 71 267 73
280 5 21 3 22 -70 22 l-75 0 -20 -88 c-12 -48 -31 -130 -43 -181 l-22 -95 -34
130 c-19 71 -40 153 -49 182 l-15 52 -63 0 -63 0 -43 -167 c-24 -93 -46 -173
-49 -180 -4 -7 -24 67 -47 165 l-40 177 -72 3 c-67 3 -72 2 -72 -18z"/>
<path d="M3404 3327 c-3 -8 -4 -133 -2 -278 l3 -264 68 -3 67 -3 0 86 0 85 65
0 c37 0 65 -4 65 -10 0 -5 19 -44 42 -85 l42 -75 73 0 c40 0 73 2 73 5 0 3
-21 42 -46 87 -44 77 -45 82 -28 95 10 7 21 13 25 13 4 0 17 21 29 46 19 39
22 58 18 133 -4 96 -25 146 -69 168 -36 19 -418 18 -425 0z m347 -118 c12 -23
8 -123 -5 -136 -4 -5 -49 -10 -100 -11 l-93 -4 -6 63 c-13 119 -21 109 93 109
91 0 100 -2 111 -21z"/>
<path d="M4012 3320 c-18 -11 -40 -36 -48 -55 -22 -53 -18 -143 9 -195 28 -57
62 -68 213 -69 l112 -1 -6 -47 c-2 -27 -7 -50 -9 -53 -3 -3 -76 -6 -164 -8
l-159 -3 0 -48 c0 -46 1 -49 31 -55 17 -3 98 -6 180 -6 228 0 264 23 264 170
-1 84 -20 129 -64 151 -14 7 -78 14 -141 17 -63 2 -121 9 -127 14 -16 13 -17
52 -3 79 10 17 23 19 150 19 l140 0 0 55 0 55 -172 0 c-156 0 -176 -2 -206
-20z"/>
<path d="M4510 3060 l0 -280 70 0 70 0 0 115 0 115 100 0 100 0 0 -115 0 -115
70 0 70 0 0 280 0 280 -70 0 -70 0 0 -110 0 -111 -97 3 -98 3 -3 108 -3 107
-69 0 -70 0 0 -280z"/>
<path d="M5070 3285 l0 -55 35 0 35 0 -2 -167 -3 -168 -32 -3 c-33 -3 -33 -4
-33 -58 l0 -54 140 0 140 0 0 55 0 55 -35 0 -35 0 0 170 0 170 35 0 35 0 0 55
0 55 -140 0 -140 0 0 -55z"/>
<path d="M5422 3063 l3 -278 70 0 70 0 3 77 3 76 127 4 c194 5 217 27 217 203
0 77 -4 108 -18 132 -31 58 -53 63 -277 63 l-200 0 2 -277z m348 148 c5 -11
10 -42 10 -70 0 -77 -19 -91 -124 -91 l-85 0 -3 90 -3 90 97 0 c86 0 99 -2
108 -19z"/>
<path d="M6190 3285 l0 -55 85 0 85 0 0 -225 0 -225 70 0 70 0 0 225 0 225 85
0 85 0 0 55 0 55 -240 0 -240 0 0 -55z"/>
<path d="M6720 3060 l0 -280 223 2 222 3 3 53 3 52 -150 0 -149 0 -4 60 -3 60
118 0 117 0 0 55 0 55 -114 0 -114 0 -4 55 -3 55 151 0 152 0 -2 53 -1 52
-222 3 -223 2 0 -280z"/>
<path d="M7230 3060 l0 -280 215 0 215 0 0 55 0 54 -142 3 -143 3 -3 223 -2
222 -70 0 -70 0 0 -280z"/>
<path d="M7720 3060 l0 -280 220 0 220 0 0 55 0 54 -147 3 -148 3 -3 58 -3 57
121 0 120 0 0 55 0 54 -117 3 -118 3 -3 53 -3 52 150 0 151 0 0 55 0 55 -220
0 -220 0 0 -280z"/>
<path d="M8274 3323 c-55 -27 -59 -41 -62 -244 -5 -279 4 -292 206 -298 68 -2
148 0 178 4 l54 6 0 52 0 52 -127 -3 c-189 -5 -173 -20 -173 161 0 106 4 157
12 165 8 8 56 12 150 12 l138 0 0 55 0 55 -172 -1 c-134 0 -180 -4 -204 -16z"/>
<path d="M9300 3060 l0 -280 70 0 69 0 3 158 3 157 55 -100 55 -100 55 0 55 0
55 100 55 100 3 -157 3 -158 69 0 70 0 0 280 0 280 -79 0 c-58 0 -80 -4 -83
-13 -5 -21 -142 -277 -148 -277 -3 0 -39 64 -81 143 l-76 142 -76 3 -77 3 0
-281z"/>
<path d="M10052 3320 c-18 -11 -40 -36 -48 -55 -19 -46 -18 -135 2 -185 25
-62 56 -74 204 -78 l125 -4 -3 -41 c-2 -23 -11 -48 -20 -54 -11 -9 -61 -13
-164 -13 l-148 0 0 -49 0 -50 54 -6 c29 -4 116 -5 192 -3 157 4 192 15 215 70
21 49 17 166 -6 205 -27 46 -57 56 -185 61 -63 2 -121 9 -127 14 -16 13 -17
52 -3 79 10 17 23 19 150 19 l140 0 0 55 0 55 -172 0 c-156 0 -176 -2 -206
-20z"/>
<path d="M6735 2358 c-2 -7 -11 -39 -19 -71 -14 -58 -47 -108 -135 -207 -54
-61 -64 -44 -36 63 21 79 23 82 53 85 39 4 45 32 7 32 -22 0 -25 4 -23 31 4
50 -20 47 -33 -4 -7 -29 -31 -69 -66 -112 -42 -50 -53 -69 -44 -78 9 -9 17 -4
35 18 31 38 38 31 23 -23 -10 -38 -9 -44 10 -63 31 -31 64 -19 111 41 43 54
55 56 39 5 -10 -35 -3 -75 12 -59 5 5 19 43 31 84 30 102 42 120 86 120 46 0
51 -18 31 -96 -24 -87 -22 -102 10 -110 24 -6 33 0 80 52 50 56 53 58 53 31 0
-92 121 -117 207 -42 30 26 35 36 26 46 -9 9 -20 5 -48 -20 -30 -26 -44 -31
-85 -31 -39 0 -52 4 -60 20 -16 29 -4 40 41 40 53 0 93 22 109 60 11 28 11 34
-7 58 -23 31 -62 39 -103 22 -27 -11 -87 -74 -155 -162 -45 -59 -57 -50 -35
27 14 49 16 73 9 94 -12 35 -48 55 -88 49 -17 -3 -31 -4 -31 -4 0 1 7 23 15
50 8 26 12 52 9 57 -8 13 -24 11 -29 -3z m373 -141 c16 -11 15 -42 -3 -57 -8
-6 -32 -14 -55 -17 -34 -5 -40 -3 -40 13 0 24 9 36 38 56 25 17 38 18 60 5z"/>
<path d="M7505 2360 c-4 -6 -15 -40 -25 -75 -13 -45 -33 -82 -65 -121 -31 -39
-43 -60 -36 -67 7 -7 17 -5 31 8 24 22 27 7 9 -44 -9 -24 -9 -35 0 -44 9 -9
14 -9 20 2 7 10 13 11 31 1 45 -24 98 -6 156 51 l54 53 0 -31 c0 -44 28 -73
77 -80 59 -8 121 25 180 95 27 32 52 60 58 64 11 8 55 -58 55 -84 0 -10 -11
-25 -24 -33 -20 -13 -29 -14 -46 -5 -32 17 -53 -5 -25 -26 40 -30 91 -12 163
58 68 66 70 66 46 -14 -6 -21 29 -58 55 -58 25 0 91 74 91 103 0 19 -6 16 -46
-27 -60 -64 -73 -53 -49 41 25 94 31 103 65 103 39 0 41 27 3 32 -25 3 -28 6
-25 37 5 49 -18 35 -37 -22 -9 -25 -36 -70 -61 -98 -25 -28 -49 -58 -53 -65
-9 -16 -27 -19 -27 -4 0 6 -11 28 -25 50 -17 27 -23 48 -19 64 12 49 -15 33
-86 -50 -84 -98 -112 -120 -162 -129 -29 -6 -40 -3 -58 15 -31 31 -20 44 43
51 64 7 79 17 97 60 11 27 10 34 -7 57 -41 55 -98 41 -175 -40 l-57 -61 2 43
c2 56 -37 94 -90 88 -31 -4 -34 -2 -29 16 15 52 18 96 8 96 -6 0 -14 -5 -17
-10z m319 -136 c17 -6 21 -40 8 -61 -5 -7 -29 -16 -55 -19 -42 -6 -47 -5 -47
12 0 24 9 36 40 57 27 19 31 20 54 11z m-228 -28 c9 -38 -18 -116 -48 -139
-23 -17 -31 -19 -53 -9 -20 9 -25 19 -25 47 0 39 24 105 43 117 7 4 27 8 44 8
26 0 34 -5 39 -24z"/>
<path d="M4013 2334 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
-16z"/>
<path d="M4483 2334 c-10 -26 14 -41 35 -21 15 16 15 19 2 27 -21 14 -30 12
-37 -6z"/>
<path d="M3715 2246 c-37 -16 -95 -87 -95 -117 0 -19 2 -20 17 -8 14 12 17 12
21 -1 3 -8 25 -35 49 -61 52 -56 56 -93 11 -118 -25 -14 -29 -14 -57 7 -40 29
-55 8 -17 -25 28 -24 65 -30 95 -14 9 6 62 62 116 125 54 64 100 114 103 112
2 -3 -2 -27 -8 -54 -16 -59 -9 -82 24 -82 17 0 42 20 85 68 l62 67 -7 -52 c-4
-36 -3 -58 6 -68 26 -32 91 -11 140 45 17 20 29 23 69 21 43 -2 51 1 75 30 15
17 29 30 31 27 2 -2 -3 -27 -11 -56 -17 -58 -9 -82 27 -82 17 0 70 50 128 120
25 31 27 15 6 -54 -10 -34 -12 -53 -5 -60 15 -15 25 2 46 79 26 101 89 162
124 120 14 -17 10 -51 -14 -127 -13 -42 -13 -49 0 -63 26 -26 45 -17 102 45
54 59 72 71 72 49 0 -6 20 -34 45 -61 51 -57 57 -90 21 -113 -24 -16 -66 -9
-81 14 -9 16 -25 13 -25 -4 0 -43 83 -71 125 -41 28 20 200 224 200 238 0 26
-23 14 -63 -36 -24 -29 -55 -65 -69 -80 l-25 -28 -19 28 -18 28 29 17 c38 23
64 74 56 110 -4 20 -1 31 12 38 46 28 -71 43 -131 18 -30 -13 -65 -49 -148
-154 -34 -41 -67 -58 -55 -28 3 9 13 43 22 77 15 58 15 63 -4 87 -22 30 -55
38 -88 20 -18 -9 -27 -9 -37 0 -12 9 -31 -9 -101 -94 -74 -90 -106 -120 -106
-100 0 2 12 45 26 95 19 66 23 95 15 103 -7 7 -17 3 -32 -17 -53 -68 -103
-116 -121 -116 -22 0 -22 7 -2 55 14 33 14 39 -1 61 -21 32 -58 31 -84 -2 -25
-31 -26 -55 -6 -93 13 -27 13 -30 -13 -53 -15 -14 -38 -31 -51 -38 -42 -21
-45 0 -16 100 29 104 23 136 -17 84 -13 -16 -30 -36 -38 -44 -8 -9 -40 -46
-70 -84 -32 -40 -56 -62 -58 -53 -2 7 6 47 18 88 21 77 25 119 12 119 -8 0
-189 -209 -205 -236 -12 -21 -25 -17 -42 15 -14 28 -13 30 3 36 37 11 65 53
66 97 1 24 7 48 15 54 8 6 11 16 7 22 -10 16 -104 14 -141 -2z m591 -30 c6
-17 -12 -56 -26 -56 -11 0 -14 53 -3 63 11 11 23 8 29 -7z m-508 -33 c4 -44
-12 -70 -48 -78 -39 -9 -60 8 -60 48 0 44 26 69 69 65 32 -3 36 -6 39 -35z
m1250 8 c6 -40 -7 -71 -35 -82 -61 -23 -96 33 -57 89 12 18 24 22 52 20 30 -2
38 -7 40 -27z"/>
<path d="M5440 2253 c0 -5 -15 -24 -33 -43 -62 -66 -89 -108 -76 -116 8 -5 25
7 45 32 l33 39 -5 -50 c-5 -42 -2 -55 17 -79 19 -24 27 -28 61 -23 41 5 43 2
28 -37 -13 -36 -44 -50 -83 -37 -40 14 -57 -1 -26 -23 49 -34 102 -14 164 61
81 96 123 141 128 136 3 -3 3 -11 1 -18 -8 -20 23 -73 47 -80 41 -13 88 4 112
40 18 29 28 35 57 35 27 0 41 7 63 32 l28 33 -7 -47 c-5 -41 -3 -50 19 -72 28
-28 65 -33 98 -15 15 7 24 7 35 -1 24 -21 58 -1 112 67 38 48 50 69 42 77 -8
8 -19 3 -37 -19 -72 -83 -85 -97 -90 -91 -3 3 4 47 17 97 16 64 19 94 12 101
-13 13 -20 -2 -54 -112 -25 -80 -46 -101 -93 -92 -31 5 -33 38 -9 123 9 32 13
66 10 76 -6 15 -12 12 -42 -22 -20 -22 -47 -55 -61 -72 -14 -18 -34 -33 -43
-33 -16 0 -18 7 -13 53 6 62 -1 71 -69 81 -42 6 -49 4 -82 -25 -20 -17 -66
-67 -102 -110 -36 -43 -69 -79 -73 -79 -5 0 5 45 22 101 21 73 26 104 18 112
-15 15 -18 10 -46 -84 -32 -111 -78 -154 -119 -113 -20 19 -20 19 5 109 11 41
17 79 14 85 -7 12 -25 13 -25 3z m360 -68 c0 -22 8 -43 20 -55 11 -11 20 -26
20 -33 0 -36 -79 -68 -101 -41 -15 19 -4 90 21 132 26 42 40 41 40 -3z m65 0
c0 -32 -19 -45 -29 -19 -9 22 3 56 17 51 7 -2 12 -16 12 -32z"/>
<path d="M11292 878 l-2 -248 153 2 152 2 -115 5 -115 6 -5 240 -5 240 -5
-240 c-5 -232 -6 -240 -25 -240 -19 0 -20 8 -25 240 l-6 240 -2 -247z"/>
<path d="M11680 1111 c0 -7 9 -25 21 -39 26 -33 169 -235 252 -356 34 -49 63
-84 65 -78 2 6 -17 39 -42 73 -25 35 -46 68 -46 74 0 6 33 58 74 115 41 58 87
123 103 145 l28 40 5 -230 6 -230 2 248 c1 137 -2 246 -6 243 -5 -3 -22 -24
-38 -48 -39 -58 -175 -251 -184 -261 -4 -5 -56 60 -115 145 -122 174 -125 177
-125 159z"/>
<path d="M11670 826 c0 -117 4 -186 10 -186 6 0 10 62 10 165 0 91 3 165 8
165 4 0 26 -28 49 -63 126 -187 202 -289 202 -272 0 15 -252 368 -266 373 -10
3 -13 -38 -13 -182z"/>
<path d="M11282 473 c-2 -45 1 -53 16 -53 11 0 13 3 5 8 -6 4 -13 28 -16 52
l-4 45 -1 -52z"/>
<path d="M11420 500 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M11711 478 c1 -28 3 -34 6 -18 3 14 9 30 14 36 5 6 6 14 2 17 -16 16
-23 4 -22 -35z"/>
<path d="M11807 478 c8 -59 13 -61 13 -5 0 26 -4 47 -9 47 -5 0 -7 -19 -4 -42z"/>
<path d="M11866 504 c-4 -9 -4 -19 -1 -22 3 -3 5 0 5 6 0 25 19 10 30 -24 7
-19 14 -33 16 -31 7 7 -27 87 -36 87 -4 0 -11 -7 -14 -16z"/>
<path d="M12033 470 c0 -30 2 -43 4 -28 2 15 12 29 21 31 16 4 16 5 0 6 -9 0
-19 11 -21 24 -2 12 -4 -3 -4 -33z"/>
<path d="M11561 494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M11638 483 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M11944 481 c0 -11 5 -17 13 -14 10 4 11 9 2 19 -15 18 -15 18 -15 -5z"/>
<path d="M12144 486 c-8 -9 -8 -17 1 -29 10 -14 12 -12 12 13 0 17 -1 30 -1
30 -1 -1 -6 -7 -12 -14z"/>
<path d="M11341 474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M11450 474 c0 -6 7 -19 16 -30 14 -18 14 -18 3 6 -14 31 -19 36 -19
24z"/>
<path d="M11560 456 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M11850 459 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3
-20 1 -20 -4z"/>
<path d="M11756 447 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
